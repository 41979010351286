.LANDING{
    width: 100%;
    overflow-x: hidden;
    .HEADER{
        width: 100%;
        height: 6rem;

        .marvelLogo{
            width: 65px;
            height: 65px;
        }
    }
    .sideBtn{
        position: absolute;
        top:5.1rem;
        right: 230px;
    }
    .sidebar{
        position: absolute;
        z-index: 1000;
        top:4.6rem;
        right: 0px;
        bottom: 0px;
        width: 50px;
        height: 100%;

        .sideLi{
            margin-right: 5px;
            &:hover{
                transition: all 0.3s;
                transform: translate(-5px , 0px);
            }
        }

    }
    .outlet{
        margin-right: 15px;
    }
}