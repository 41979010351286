.MODALINFO{
    min-height: auto;
    z-index: 1000;
    width: 100vw;
    position: fixed;
    top:0;
    left: 0;
    background: #000000aa;
    .MODALINFO-content{
        width: 100% ; 
        height: 100%;  
        padding: 3rem 5rem;

        .MODALINFO-body{
            overflow: scroll;
            height: 90vh;
            border-radius: 10px;
        }
    }
}