.CATEGORY{
    margin: 20px 0px;
    .category-title{
        position: absolute;
        top: -20px;
        right: 10px;
        z-index: 990;
    }
    .categoryBtn{
        border: 1px solid skyblue;
        color: skyblue;
        background-color: white;
        cursor: pointer;

        &:hover{
            background-color: #0d6efd ;
            color: white;
            border: 1px solid #0d6efd;
        }
        // &:active{
        //     background-color: #0d6efd ;
        //     color: white;
        //     border: 1px solid #0d6efd;
        // }
        // &:focus{
        //     background-color: #0d6efd ;
        //     color: white;
        //     border: 1px solid #0d6efd;
        // }
    }
}