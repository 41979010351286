
.GETINFORMATION{
    overflow-x: hidden;
    height: 200vh;
    .TABS{
      margin-right: 50px;
    }
    .btnCrypto{
      background-color: #D75ADE;
      &:hover{
        background-color: darken($color: #D75ADE, $amount: 15%);
      }
    }
    .resultSearch{
        height: 300px;
        overflow-y: auto;

    }
    ::-webkit-scrollbar {
        width: 5px;
      }
      
      /* Track */
      ::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px rgb(202, 202, 202); 
        border-radius: 5px;
      }
       
      /* Handle */
      ::-webkit-scrollbar-thumb {
        background: #37C190; 
        border-radius: 5px;
      }
      
      /* Handle on hover */
      ::-webkit-scrollbar-thumb:hover {
        background: #b30000; 
      }
    .outline-none{
        outline: none;
    }
}