.EDIRINFORMATION{
    overflow-x: hidden;
    .overflow-x-auto{
        overflow-x: auto;
        overflow-y: hidden;
        height : 495px;
    }
    
    .information-table{
        width: 100%;
        table {
            direction: rtl;
            border-collapse: separate;
            width: 100%;
          }
          
          th {
            padding: 10px;
            text-align: right;
            border-bottom: 1px solid #ddd;
          }

          td{
              padding: 10px 10px;
            text-align: right;
            border-bottom: 1px solid #ddd;
           
          }
    }
    }

    @media screen and (max-width : 900px) {
      .EDIRINFORMATION{
        .overflow-x-auto{
          overflow-x: auto;
          height : auto;
      }
      }
    }
    