.Report{
    margin: 20px 0px;
    overflow-x: hidden;
    height: 100vh;
    .report-title{
        position: absolute;
        top: -20px;
        right: 10px;
        z-index: 990;
    }

    .download-btn{
        background-color: #20C197;
        cursor: pointer;
        &:hover{
            background-color: darken($color: #20C197, $amount: 10%);
        }
    }
}