.outlet-none{
    outline: none;
}

.decoration-none{
    text-decoration: none;
}

.listStlye-none{
    list-style: none;
}